import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/model/page',
    update:'/admin/v1/model/update',
    create:'/admin/v1/model/create',
    del:'/admin/v1/model/del',
    get_model:'/admin/v1/model/get',
}


export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function get_model(data)
{
    return $h.request(url.get_model,data,'GET')
}